import {
  CheckCircleIcon,
  ChevronLeftIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import Layout from '../../components/layout/layout';
import { Headline } from '../../components/layout/typography';

const BootcampPage = () => {
  return (
    <Layout
      title="Founders' Bootcamp"
      description="Learn how to build a successful startup with the Founders' Bootcamp"
    >
      <section id="solution" className="pt-32 bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <div>
              <nav aria-label="Back">
                <a
                  href="/services"
                  className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <ChevronLeftIcon
                    className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Back
                </a>
              </nav>
            </div>
          </div>
          <div className="py-12 w-full flex flex-col items-center">
            <p className="block text-base text-center font-semibold tracking-wide uppercase">
              Learn how to build a successful startup with the
            </p>
            <Headline className="mt-2 block text-5xl text-center font-extrabold tracking-tight text-6xl sm:text-4xl tracking-tight font-extrabold lg:text-5xl xl:text-6xl">
              Founders&apos; <br />
              Bootcamp
            </Headline>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <StaticImage
                      className="w-full"
                      src="../../images/bootcamp.jpg"
                      alt="A picture of a founder taking the Founders' Bootcamp"
                      placeholder="blurred"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="mt-6 prose prose-red prose-lg text-gray-500 pb-8">
                <h2>
                  This course will show you how to build a successful
                  startup&mdash;driven by experiments and early traction.
                </h2>
              </div>
              <div className="text-lg text-gray-500 max-w-prose mx-auto lg:max-w-none">
                <p className="mb-4">
                  Within roughly 16 hours of course material, I will guide you
                  through the process of building a revenue generating business
                  without writing code for months. For this, we will use one of
                  my business ideas as an example and go through the whole
                  process from start to finish.
                </p>
              </div>
              <div className="mt-5 prose prose-red text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <h3>That&apos;s in it for you</h3>
                <ul role="list">
                  <li>
                    Learn how a Lean Canvas works and why it&apos;s such an
                    important tool for data-driven decision-making.
                  </li>
                  <li>
                    Learn how to identify the parts of your business you need
                    data on first by using a process called assumption mapping.
                  </li>
                  <li>
                    Get to know all the different kinds of experiments and
                    methodology to get data on those assumptions.
                  </li>
                  <li>
                    Learn to design your own experiments based on Test Cards.
                  </li>
                  <li>
                    Learn to evaluate your experiments and identify the next
                    iteration.
                  </li>
                  <li>
                    Gradually transform the generated insights and traction into
                    a revenue generating business.
                  </li>
                </ul>
                <h3>Get on the waiting list</h3>
                <p className="mb-4">
                  I am still working on the course, but will be launching it
                  soon. By filling out the following form you can sign up to get
                  a notification when it&apos;s ready.
                </p>
              </div>
              <Form />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BootcampPage;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const encode = (data) => {
  const encoded = new URLSearchParams(data).toString();
  return encoded;
};

const Form = () => {
  const [mail, setMail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  function handleMailChange({ target: { value } }) {
    setMail(value);
  }

  function isValidMail(email) {
    var emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.match(emailRegex)) {
      return false;
    }

    return true;
  }

  function handleSubmit(e) {
    if (!isValidMail(mail)) {
      setError('Bitte geben Sie eine gültige E-Mail-Adresse ein');
      e.preventDefault();
      return;
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'bootcamp', email: mail }),
    })
      .then(() => setSuccess(true))
      .catch((error) => setError(error));

    e.preventDefault();
  }

  const enabled = !!mail;

  return (
    <div id="form" className="mt-4">
      {success ? (
        <Success />
      ) : (
        <form
          name="bootcamp"
          method="POST"
          data-netlify="true"
          className="sm:max-w-xl sm:mx-auto lg:mx-0"
          onSubmit={handleSubmit}
        >
          <div className="sm:flex mt-3">
            <div className="min-w-0 flex-1">
              <label htmlFor="email" className="sr-only">
                E-Mail Address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={mail}
                onChange={handleMailChange}
                placeholder="Enter E-Mail Address"
                className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
              />
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <button
                type="submit"
                className={classNames(
                  enabled
                    ? 'text-white bg-timothy-purple hover:bg-purple-700 cursor-pointer'
                    : 'text-gray-400 bg-gray-200 hover:bg-gray-200 cursor-not-allowed',
                  'block w-full py-3 px-4 rounded-md shadow text-white font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300 focus:ring-offset-gray-900'
                )}
              >
                Notify me!
              </button>
            </div>
          </div>
          {!!error && (
            <div className="rounded-md bg-red-50 p-4 mt-2">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-red-800">{error}</p>
                </div>
              </div>
            </div>
          )}
          <p className="mt-3 text-sm text-gray-400">
            I care about the protection of your data. By pressing &quot;Notify
            me!&quot;, you agree to receiving updates on my products and blog
            posts via mail (max. 1 per week). You can unsubscribe at any time.
            For further information, please read the{' '}
            <Link to="/privacy" className="text-gray-600 font-medium underline">
              Privacy Policy
            </Link>
            .
          </p>
        </form>
      )}
    </div>
  );
};

function Success() {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">
            Successfully sent! Please check your inbox!
          </p>
        </div>
      </div>
    </div>
  );
}

